import { LitElement, html } from 'lit';

class InfoBox extends LitElement {
  NORMAL_TYPE = 'normal';
  ERROR_TYPE = 'error';
  
  // Disable shadow DOM and allow global style to take over
  createRenderRoot() {
    return this; // Render directly into the component's element
  }
  
  static get properties() {
    return {
      type: { type: String, reflect: true },
      message: { type: String },
    };
  }

  constructor() {
    super();
    this.type = 'info';
    this.message = '';
  }

  render() {
    return html`
      <p class=${this._classForType()}>
        ${this.message}
      </p>
    `;
  }

  _classForType() {
    switch(this.type) {
      case this.NORMAL_TYPE:
        return 'pico-color-slate-500';
      case this.ERROR_TYPE:
        return 'pico-color-orange-500';
      default:
        return 'pico-color-grey-500'
    }
  } 
}

customElements.define('info-box', InfoBox);
