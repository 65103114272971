import { LitElement, html } from 'lit';

class FileInput extends LitElement {
  // Disable shadow DOM and allow global style to take over
  createRenderRoot() {
    return this; // Render directly into the component's element
  }

  static get properties() {
    return {
      file: { type: File } // Define the file property (optional)
    };
  }

  constructor() {
    super();
    this.file = null;
  }

  render() {
    const fileSizeMB = this.file ? (this.file.size / (1024 * 1024)).toFixed(2) : 0;
    return html`
      ${this.file
        ? html`
            <div>
              <p>Filename: ${this.file.name}<span> (${fileSizeMB} MB)</span></p>
              
            </div>
            <button @click=${this._handleConfirm}>Confirm File</button>
            <button class='pico-background-pink-500' @click=${this._handleReset}>Reset</button>
          `
        : html`
          <input id='file-input' type="file" accept=".pdf" @change=${this._handleFileChange} />
        `}
    `;
  }

  _handleFileChange(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        this.file = file; // Update the file property
      } else {
        this.file = null;
        event.target.value = ''; // Clear the input
        alert('Please select a PDF file.');
      }
    }
  }

  _handleConfirm() {
    if (this.file) {
      this.dispatchEvent(
        new CustomEvent('file-confirmed', {
          detail: { file: this.file },
          bubbles: true,
          composed: true
        })
      );
      this.file = null;
    }
  }

  _handleReset() {
    this.file = null;
  }

  get _fileInput() {
    return this.renderRoot.querySelector('#file-input');
  }
}

customElements.define('file-input', FileInput);
